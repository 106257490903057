import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/dashboard/Icons/logoorange.png";
import "./MHADescription.css";

const MHADescription = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/mental-health-assessment/step/0");
  };

  return (
    <div className="mha-bg2">
      <div className="mha2logo">
        <img src={logo} alt="JetSetMed Logo" />
        <h1>JetSetMed</h1>
      </div>
      <div className="mha-sectmha2">
        <p>
          This quick questionnaire is designed to give you a general sense of
          your current emotional state. It covers symptoms of depression,
          anxiety, and stress.
        </p>
        <p>Here's the thing; there are no right or wrong answers.</p>
        <p>
          Be honest with yourself and choose the response that best reflects
          your experience over the past week.
        </p>
      </div>

      <div className="mha-next-btn-container">
        <button onClick={handleNext} className="mha-next-btn">
          Next {"->"}
        </button>
      </div>
    </div>
  );
};

export default MHADescription;
