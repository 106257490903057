import React, { useState } from 'react'
import "./ClientList.css"
import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";
import ClientSearch from '../../../Utilities/ClientSearch/ClientSearch';
import ClientCard from "../../../components/Admin/ClientCard/ClientCard";
import ClientClick from '../../../components/Admin/ClientClick/ClientClick';

const ClientList = () => {
  return (
    <>
    <AdminLayout title={"Client List"}>
      <ClientSearch/>
      <div className="crows">
        <div className="ccol">
          <ClientCard/>
          <ClientCard/>
          <ClientCard/>
          <ClientCard/>
          <ClientCard/>
          <ClientCard/>
        </div>
        <div className="ccol">
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        </div>
        <div className="ccol">
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        <ClientCard/>
        </div>
      </div>
    </AdminLayout>
  </>
  )
}

export default ClientList
