import React, { useState } from 'react'
import "./ClientCard.css"
import pfp from "../../../images/dashboard/pfp.png"
const Client = () => {
  
  return (
    <div className="ccbox">
      <img src={pfp} />
      <div className="cctxt">
      <h1>Gagan N Bangaragiri</h1>
      <p>Premium Tier</p>
      </div>
    </div>
  )
}

export default Client
