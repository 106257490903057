import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/dashboard/Icons/LOGO.png";
import tra from "../../../images/dashboard/Icons/trafirst.png";
import "./TRAOpening.css";

const TRAOpening = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/travel-risk-assessment/description");
  };
  return (
    <div className="bg">
      <div className="t1logo">
        <img src={logo} alt="JetSetMed Logo" />
        <h1>JetSetMed</h1>
      </div>
      <div className="secttra">
        <div className="tra">
          <img src={tra} alt="Travel Risk Assessment Icon" />
        </div>
        <div className="txtsec">
          <h1>Welcome to our</h1>
          <h1>Travel Risk Assessment</h1>
        </div>
      </div>
      <div className="next-btn-container">
        <button onClick={handleNext} className="next-btn">
          Next {"->"}
        </button>
      </div>
    </div>
  );
};

export default TRAOpening;
