import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import { toast } from 'react-toastify';

const SignUpForm = ({ onSignUpSuccess }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    const isPhoneValid = /^\d{10}$/.test(formData.phoneNumber);
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    setIsFormValid(isPhoneValid && isEmailValid);
  }, [formData.phoneNumber, formData.email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(formData.password)) {
      toast.error("Password should contain 1 Number, 1 Uppercase, 8 Length and 1 symbol");
      return; 
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/signup`,
        {
          ...formData,
          role: "user", // default value
          country: "Unknown", // default value
          profilePhoto: "", // default value
        }
      );

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role);
        toast.success("Sign up successful!");
        onSignUpSuccess();
      }
    } catch (error) {
      console.error("Sign up failed:", error.response?.data?.message || error.message);
      toast.error("Sign up failed. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="loginForm">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 g-0 w-100">
        <div className="loginForm_input mb-2">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="Vishal"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="loginForm_input mb-2">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            placeholder="Kumar"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-100 mb-2">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="vishal@gmail.com"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="w-100 mb-2">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      <div className="w-100 mb-2">
        <label htmlFor="phoneNumber">Phone No</label>
        <input
          type="tel"
          className="form-control"
          id="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>
      <div className="w-100 my-3">
        <button type="submit" className="btn authBtn" disabled={!isFormValid}>
          <span>Sign Up</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
      <div className="w-100 text-center redirectLink">
        <Link to="/login">
          <span>Already Have an account ?</span>
          <span className="redirectLinkTag"> Sign In</span>
        </Link>
      </div>
    </form>
  );
};

export default SignUpForm;
