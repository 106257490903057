const qnaData = [
  {
    questions: [
      { question: "What is your name?", type: "name" },
      {
        question: "What is your age?",
        type: "multiple",
        options: ["16-20", "20-30", "30-40", "40-50", "50-60", "60+"],
      },
    ],
  },
  {
    questions: [
      {
        question: "What is your gender?",
        type: "multiple",
        options: ["Male", "Female", "Other"],
      },
      { question: "What is your phone number?", type: "phone" },
      { question: "What is your email address?", type: "email" },
    ],
  },
  {
    questions: [
      {
        question: "Why are you travelling right now?",
        type: "multiple",
        options: ["Leisure Trip", "Business Trip", "Long Term Stay", "Overseas Education", "Ex-Patriot", "Other"],
      },
      {
        question: "How long do you usually travel?",
        type: "multiple",
        options: ["Upto 2 weeks (Annually)", "More than a month(Annually)", "More than a Year"],
      },
    ],
  },
  {
    questions: [
      { question: "Where are you going?", type: "text" },
      { question: "Why are you going there?", type: "text" },
      {
        question: "What kind of place is it?",
        type: "multiple",
        options: ["Urban", "Rural", "Jungle", "Desert", "Costal", "High Altitude"],
      },
    ],
  },
  {
    questions: [
      {
        question: "Where are you staying?",
        type: "multiple",
        options: ["Hotel", "Hostel/Dorm", "Camping", "Friends or Family", "Renting/Leasing an Apartment"],
      },
      {
        question: "Do you have travel insurance?",
        type: "multiple",
        options: ["Yes", "No", "Need to discuss with my doctor"],
      },
    ],
  },
  {
    questions: [
      { question: "Do you have hair loss?", type: "boolean" },
      { question: "Does your family have pattern baldness?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Do you have dandruff?", type: "boolean" },
      { question: "Do you have an oily scalp?", type: "boolean" },
      { question: "Are you visiting a cold country?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Is your scalp occasionally dry?", type: "boolean" },
      { question: "Have you ever had to visit the dermatologist for your scalp? ", type: "boolean" },
      { question: "What was the issue with your scalp?", type: "text" },
    ],
  },
  {
    questions: [
      {
        question: "What do you experience regarding headaches?",
        type: "multiple",
        options: ["Migraines", "Frequent Mild Headaches", "Average Headaches"],
      },
      {
        question: "How severe are the headaches?",
        type: "slider",
        min: 1,
        max: 10,
      },
      {
        question: "How long do the headaches last?",
        type: "multiple",
        options: ["Few Minutes", "Less than an Hour", "Few Hours"],
      },
    ],
  },
  {
    questions: [
      {
        question: "Frequency of your headaches in a week?",
        type: "multiple",
        options: ["<1", "1-2 Times", "3-4 Times", "5-6 Times", "Daily"],
      },
      { question: "Do you have any headaches triggers?", type: "text" },
      { question: "When do you get your headaches?",
        type: "multiple",
        options: ["Mornings", "Evenings"],
      },
    ],
  },
  {
    questions: [
      {
        question: "Do you utilize any eyewear?",
        type: "multiple",
        options: ["Spectacles", "Contact Lenses", "Neither"],
      },
      { question: "Have you been checked for numbered vision in the past year?", type: "boolean" },
      { question: "Have you been evaluated by an optometrist/ophthalmologist recently?", type: "boolean" },
    ],
  },
  {
    questions: [
      {
        question: "Have you ever had these eye conditions?",
        type: "multiple",
        options: ["Conjunctivitis/Pink Eye", "Stye on Eyelids", "Bloodshot/Red Eyes"],
      },
    ],
  },
  {
    questions: [
      {
        question: "Do you have any skin problems?",
        type: "multiple",
        options: ["Acne", "Mild Pimples", "Other"],
      },
      {
        question: "What level is your acne?",
        type: "multiple",
        options: ["Grade 1", "Grade 2", "Grade 3", "Grade 4"],
      },
      { question: "What are these skin problems?", type: "text" },
    ],
  },
  {
    questions: [
      { question: "Have you ever had ring worm or fungal infections or itching in areas like armpits, between toes, etc?", type: "boolean" },
      { question: "We advise adding an antifungal to your medical kit for athletes toe and jock itch if you perform sports and perspire a lot.", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Do you have any ear pain/discharge?", type: "boolean" },
      { question: "When was your last visit to the dentist? For what was it?", type: "text" },
      { question: "Do you have any oral ulcers?", type: "boolean" },
    ],
  },
  {
    questions: [
      {
        question: "Respiratory and Sinus Ailments (Choose whichever applies to you)",
        type: "multiple",
        options: ["Sinusitis", "Sore Throat", "Recurrent Colds", "Pollen/Dust Allergy", "Cough"],
      },
      {
        question: "Frequency of these ailments per month?",
        type: "slider",
        min: 1,
        max: 10,
      },
    ],
  },
  {
    questions: [
      { question: "Do you have a history of asthma or any breathing difficulties?", type: "boolean" },
      { question: "What is its frequency?", type: "text" },
      { question: "What usually triggers it?", type: "text" },
    ],
  },
  {
    questions: [
      {
        question: "Do you have heartburn/acidity?",
        type: "multiple",
        options: ["Indigestion", "Constipation", "Gas"],
      },
      { question: "Do you have gastritis?", type: "boolean" },
      {
        question: "How many times in a month?",
        type: "slider",
        min: 1,
        max: 10,
      },
    ],
  },
  {
    questions: [
      {
        question: "Any gastro-intestinal issues?",
        type: "multiple",
        options: ["Indigestion", "Constipation", "Gas"],
      },
      { question: "Have you had typhoid or cholera?", type: "boolean" },
      { question: "Did you have to be hospitalized?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Have you had a UTI or Kidney/Ureter Stones", type: "boolean" },
      { question: "History of Fractures?", type: "boolean" },
      { question: "Will you be participating in sports/adventure activities?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Any sleep disturbances or jet lag?", type: "boolean" },
      { question: "Do you have motion sickness or are you going on any boats?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Do you drink alcohol?", type: "boolean" },
      { question: "How many times in a month?", type: "text" },
      { question: "How much do you drink? (in mL)", type: "text" },
      { question: "Do you smoke?", type: "boolean" },
      { question: "How many cigarettes do you smoke in a day?", type: "text" },

    ],
  },
  {
    questions: [
      { question: "Do you take drugs?", type: "boolean" },
      { question: "What do you take?", type: "text" },
      { question: "How much do you take?", type: "text" },
      { question: "How often do you take drugs?", type: "text" },
      { question: "Do you drink coffee or tea?", type: "boolean" },
      { question: "How many cups do you drink in a day?", type: "text" },

    ],
  },
  {
    questions: [
      { question: "Are your menstrual cycles regular?", type: "boolean" },
      { question: "What is your cycle length?", type: "text" },
      { question: "What is your menstrual duration?", type: "text" },
    ],
  },
  {
    questions: [
      { question: "Do you use pads?", type: "boolean" },
      { question: "Do you have any existing PCOS?", type: "boolean" },
      { question: "Any dysmennorhea(pain during menses)?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Please give details of any medication you are taking, including any prescribed, self-treatment, over-the-counter remedies and contraception (If you are on multiple)", type: "text" },
      { question: "If you have a prescribed list from a GP, please upload it below", type: "text" },
    ],
  },
  {
    questions: [
      { question: "Do you have any existing health conditions?", type: "boolean" },
      { question: "What condition(s) do you have?", type: "text" },
      { question: "Do you have any allergies?", type: "boolean" },
      { question: "What allergy/allergies do you have?", type: "text" },
    ],
  },
  {
    questions: [
      { question: "Do you have/had a condition that could impair your immune system?", type: "boolean" },
      { question: "What condition(s) do you have?", type: "text" },
      { question: "In the last 12 months have you taken any medication that could impair your immune system?", type: "boolean" },
      { question: "What medication(s) have you taken?", type: "text" },
    ],
  },
  {
    questions: [
      { question: "Have you ever had a surgery?", type: "boolean" },
      { question: "What was it for?", type: "text" },
      { question: "Are you receiving regular treatment or follow up with your GP/hospital specialist?", type: "boolean" },
      { question: "What is the treatment for?", type: "text" },
    ],
  },
  {
    questions: [
      { question: "Do you have any disability or mobility problems?", type: "text" },
      { question: "Do you or a relative, have epilepsy or seizures?", type: "boolean" },
      { question: "Have you, or anyone in your family, ever had a severe reaction to a vaccine or malaria medication?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Are you sexually active?", type: "boolean" },
      {
        question: "Are you or your partner pregnant or planning a pregnancy?",
        type: "multiple",
        options: ["Yes", "No", "Not Applicable"],
      },
      { question: "Do you breastfeed?", type: "boolean" },
    ],
  },
  {
    questions: [
      {
        question: "Childhood/ National Immunization schedule (NIS) vaccination programme of India up-to-date?",
        type: "multiple",
        options: ["Yes", "No", "Not Sure"],
      },
      { question: "Has your university/ business required you to take certain boosters?", type: "boolean" },
    ],
  },
  {
    questions: [
      { question: "Have you felt excessive anxiety or sadness recently?", type: "boolean" },
      { question: "Have you, or a relative ever experienced any mental health issue?", type: "boolean" },
    ],
  },
];

export default qnaData;
