import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const UpdatePasswordComp = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Here you would typically make an API call to update the password
      // For example: await updatePassword(oldPassword, newPassword);
      
      // On success, navigate to login page
      navigate("/login");
    } catch (error) {
      console.error("Failed to update password:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <form onSubmit={handleSubmit} className="loginForm d-flex justify-content-center align-items-center h-100 w-100" style={{ flexDirection: "column" }}>
      <div className="w-100 mb-2">
        <label htmlFor="oldPassword">Old Password</label>
        <input
          type="password"
          className="form-control"
          id="oldPassword"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
        />
      </div>
      <div className="w-100 mb-2">
        <label htmlFor="newPassword">New Password</label>
        <input
          type="password"
          className="form-control"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>
      <div className="w-100 my-3">
        <button type="submit" className="btn authBtn">
          <span>Update Password</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
    </form>
  );
};

export default UpdatePasswordComp;
