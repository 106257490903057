import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularChart = () => {
  const totalClients = 2346;
  const freeUsersPercentage = 60; // For example, 60% are free users
  const premiumUsersPercentage = 100 - freeUsersPercentage; // The remaining percentage for premium users

  return (
    <div style={{ width: '190px', margin: '0 auto' }}>
      <CircularProgressbar
        value={freeUsersPercentage}
        text={`${totalClients} Total Clients`}
        styles={buildStyles({
          pathColor: '#FF8C00', // Free Users (Orange)
          trailColor: '#87CEEB', // Premium Users (Light Blue)
          textColor: '#FFFFFF', // Center text color
          backgroundColor: '#1D3557', // Background color
          textSize: '9px',
        })}
      />
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center',marginRight:'15px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#FF8C00', marginRight: '5px' }}></div>
          <span style={{ fontSize: '11px',marginRight:'5px',color:'white'}}>Free Users</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#87CEEB', marginRight: '5px' }}></div>
          <span style={{ fontSize: '11px',color:'white'}}>Premium Users</span>
        </div>
      </div>
    </div>
  );
};

export default CircularChart;
