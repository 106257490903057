import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import UpdatePassword from "./pages/auth/UpdatePassword";
import Dashboard from "./pages/dashboard/Dashboard/Dashboard";
import AppointmentHistory from "./pages/dashboard/AppointmentHistory/AppointmentHistory";
import MedicalReports from "./pages/dashboard/MedicalReports/MedicalReports";
import OurServices from "./pages/dashboard/OurServices/OurServices";
import TravelRiskAssessment from "./pages/qna/TravelRiskAssessment/TravelRiskAssessment";
import Admin from "./pages/admin/Admin/Admin";
import ClientList from "./pages/admin/ClientList/ClientList";
import TRADescription from "./pages/qna/TRADescription/TRADescription";
import TRAOpening from "./pages/qna/TRAOpening/TRAOpening";
import ClientClick from "./components/Admin/ClientClick/ClientClick";
import MHAOpening from "./pages/mental/MHAOpening/MHAOpening";
import MHADescription from "./pages/mental/MHADescription/MHADescription";
import MentalHealthAssessment from "./pages/mental/MentalHealthAssessment/MentalHealthAssessment";
import "./App.css";
import "./styles/navbar.css";
import "./styles/hero.css";
import "./styles/heading.css";
import "./styles/global/color.css";
import "./styles/global/font.css";
import "./styles/navbar.css";
import "./styles/videoSection.css";
import "./styles/travel.css";
import "./styles/howWeDoIt.css";
import "./styles/review.css";
import "./styles/Convinced.css";
import "./styles/compareMedicine.css";
import "./styles/pricingPlane.css";
import "./styles/footer.css";
import "./styles/auth.css";
import "./styles/otherLibrary/swiperLibrary.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TRASuccess from "./pages/qna/TRASuccess/TRASuccess";

// Custom PrivateRoute component
const PrivateRoute = ({ element, allowedRoles }) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/" replace />; 
  }

  return element;
};

// New component for public routes that should redirect when logged in
const PublicRoute = ({ element }) => {
  const token = localStorage.getItem('token');
  
  if (token) {
    return <Navigate to="/dashboard" replace />;
  }

  return element;
};

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<PublicRoute element={<Login />} />} />
          <Route path="/signup" element={<PublicRoute element={<SignUp />} />} />
          <Route path="/forget" element={<PublicRoute element={<ForgetPassword />} />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          
          {/* Protected routes for users */}
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} allowedRoles={['user']} />} />
          <Route path="/appointment-history" element={<PrivateRoute element={<AppointmentHistory />} allowedRoles={['user']} />} />
          <Route path="/medical-reports" element={<PrivateRoute element={<MedicalReports />} allowedRoles={['user']} />} />
          <Route path="/our-services" element={<PrivateRoute element={<OurServices />} allowedRoles={['user']} />} />
          
          {/* Protected routes for admins */}
          <Route path="/admin" element={<PrivateRoute element={<Admin />} allowedRoles={['admin']} />} />
          <Route path="/clientlist" element={<PrivateRoute element={<ClientList />} allowedRoles={['admin']} />} />
          <Route path="/cc" element={<PrivateRoute element={<ClientClick />} allowedRoles={['admin']} />} />
          
          {/* Other routes */}
          <Route path="/travel-risk-assessment" element={<TRAOpening />} />
          <Route path="/travel-risk-assessment/description" element={<TRADescription />} />
          <Route path="/travel-risk-assessment/step/:stepId" element={<TravelRiskAssessment />} />
          <Route path="/mental-health-assessment" element={<MHAOpening />} />
          <Route path="/mental-health-assessment/description" element={<MHADescription />} />
          <Route path="/mental-health-assessment/step/:stepId" element={<MentalHealthAssessment />} />
          <Route path="/travel-risk-assessment/success" element={<TRASuccess />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
