import React, { useState } from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/forgotPassword`,
        { email }
      );

      if (response.data.success) {
        toast.success("Password reset link sent to your email!");
        navigate('/login');
      }
    } catch (error) {
      console.error("Forgot password request failed:", error.response?.data?.message || error.message);
      toast.error("Failed to send reset link. Please try again.");
    }
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <form onSubmit={handleSubmit} className="loginForm d-flex justify-content-center align-items-center h-100 w-100" style={{ flexDirection: "column" }}>
      <div className="w-100 mb-2">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="vishal@gmail.com"
          value={email}
          onChange={handleChange}
        />
      </div>

      <div className="w-100 my-3">
        <button type="submit" className="btn authBtn">
          <span>Send</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
      <div className="w-100 my-3">
        <button type="button" className="btn authBtn" onClick={handleBack}>
          <span className="mx-2">
            <FaArrowLeft />
          </span>
          <span>Back</span>
        </button>
      </div>
    </form>
  );
};

export default ForgetPassword;
