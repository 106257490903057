const mentalData = [
    {
      questions: [
        "I couldn't seem to experience any positive feeling at all",
        "I felt like I had nothing to look forward to",
        "I felt sad and depressed",
      ],
    },
    {
      questions: [
        "I felt that I had lost interest in just about everything",
        "I felt that life wasn't worthwhile",
        "I couldn't seem to get any enjoyment out of the things I did",
      ],
    },
    {
      questions: [
        "I felt down-hearted and blue",
        "I was unable to become enthusiastic about anything",
        "I felt I wasn't worth much as a person",
      ],
    },
    {
      questions: [
        "I could see nothing in the future to be hopeful about",
        "I felt that life was meaningless",
      ],
    },
    {
      questions: [
        "I experienced breathing difficulty",
        "I had a feeling of shakiness",
        "I found myself in situations that made me so anxious I was relieved when they ended",
      ],
    },
    {
      questions: [
        "I perspired noticeably without high temperatures or exertion",
        "I felt scared without any good reason",
        "I was aware of the action of my heart without physical exertion",
      ],
    },
    {
      questions: [
        "I felt close to panic",
        "I feared that I would be 'thrown' by some trivial but unfamiliar task",
        "I was worried about situations in which I might panic and make a fool of myself",
      ],
    },
    {
      questions: [
        "I experienced trembling",
        "I felt terrified",
      ],
    },
    {
      questions: [
        "I find myself getting upset by trivial things",
        "I tended to over-react to situations",
        "I felt that I was using a lot of nervous energy",
      ],
    },
    {
      questions: [
        "I found myself getting impatient when delayed",
        "I felt that I was rather touchy",
        "I found it difficult to relax",
      ],
    },
    {
      questions: [
        "I found it hard to wind down",
        "I found it hard to calm down after something upset me",
        "I found that I was very irritable",
      ],
    },
    {
      questions: [
        "I was in a state of nervous tension",
        "I was intolerant of anything keeping me from getting on with work",
        "I found myself getting agitated",
      ],
    },
    {
      questions: [
        "I found it difficult to tolerate interruptions to what I was doing",
      ],
    },
    {
      questions: [
        "I just couldn’t seem to get going",
        "I felt that I had lost interest in just about everything",
        "I found it difficult to work up the initiative to do things",
      ],
    },
    {
      questions: [
        "I was aware of dryness of my mouth",
        "I had a feeling of faintness",
        "I had difficulty in swallowing",
      ],
    },
    {
      questions: [
        "I was aware of heart action without physical exertion",
      ],
    },
    {
      questions: [
        "I was aware of heart action without physical exertion",
      ],
    },
  ];
  
  export default mentalData;
  