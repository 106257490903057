import React from "react";
import { FaArrowRight } from "react-icons/fa";
import doctor1 from "../../images/doctor-team 1.png";
import { useNavigate } from "react-router-dom";


const Hero = () => {
  const navigate = useNavigate();
  return (
    <section id="hero">
      <div className="container-fluid h-100">
        <div className="heroChild">
          <div className="hero_child1">
            <div className="heroWrapper">
              <h1>
                Travel Anywhere, <br /> Fearlessly
              </h1>
              <p>
                Expert-curated medical kits & 24/7 tele-health for
                <br />
                worry-free adventures.
              </p>
              <button className="btn mt-3" onClick={() => navigate('/travel-risk-assessment/')}>
                <span>Book Your Pre-Trip Consultation</span>
                <span className="mx-2">
                  <FaArrowRight />
                </span>
              </button>
            </div>
          </div>
          <div className="hero_child2">
            <div className="heroWrapper_slider">
              <div className="upper">
                <img src={doctor1} alt="hero_image" className="img-fluid" />
              </div>
              <div className="lower">
                <div className="left">
                  <div className="linee"></div>
                  <ul>
                    <li><p>Telehealth, Anytime: Doctors</p></li>
                    <li><p>at your fingertips, 24/7</p></li>
                  </ul>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
