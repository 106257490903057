import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/dashboard/Icons/logogreen.png";
import "./TRADescription.css";

const TRADescription = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleNext = () => {
    navigate("/travel-risk-assessment/step/0");
  };

  const handleLogin = () => {
    navigate("/login", { state: { from: "/travel-risk-assessment/description" } });
  };

  const handleSignUp = () => {
    navigate("/signup", { state: { from: "/travel-risk-assessment/description" } });
  };

  return (
    <div className="bg2">
      <div className="t2logo">
        <img src={logo} alt="JetSetMed Logo" />
        <h1>JetSetMed</h1>
      </div>
      <div className="secttra2">
        <p>
          Before you embark on your adventure, it's essential to ensure your
          health and well-being. This questionnaire is your first step towards a
          safe and enjoyable journey. During this assessment, you'll be asked a
          series of questions about the nature of your travel and your medical
          history.
        </p>
        <p>
          Don't worry, we're not powered by AI here! Your responses will be
          carefully reviewed by a real doctor who will provide personalised
          recommendations for your medical kit.
        </p>
        <p>
          This information will help us identify potential health risks
          associated with your destination and recommend preventive measures to
          keep you safe and healthy during your travels. Consider this a way to
          prepare for the future by taking steps to minimize health risks in the
          present.
        </p>
        <p>
          By answering these questions honestly and completely, you can ensure a
          smooth and enjoyable travel experience.
        </p>
      </div>

      <div className="next-btn-container">
        {isLoggedIn ? (
          <button onClick={handleNext} className="next-btn">
            Next {"->"}
          </button>
        ) : (
          <>
            <button onClick={handleLogin} className="auth-btn">
              Login
            </button>
            <button onClick={handleSignUp} className="auth-btn">
              Sign Up
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TRADescription;
