import React from "react";
import { useParams } from "react-router-dom";
import UpHead from "../../components/auth/UpHead";
import AuthImage from "../../components/auth/AuthImage";
import ResetPasswordComp from "../../components/auth/ResetPasswordComp";

const ResetPassword = () => {
  const { token } = useParams();

  return (
    <div>
      <UpHead />
      <div className="loginDivider">
        <div className="left">
          <AuthImage />
        </div>
        <div className="right mx-2">
          <div className="headerPath">
            <h1>Reset Password</h1>
          </div>
          <div className="LoginFormComp h-100">
            <ResetPasswordComp token={token} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
