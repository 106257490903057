import React from 'react'
import "./Pendinfo.css"
import exclaim from "../../../images/exclaim.png"
import tick from "../../../images/greentick.png"
import cross from "../../../images/redcross.png"

const Pendinfo = () => {
  return (
    <div className="pa">
      <div className="pa1">
        <h1 className="paid">VDR234576543</h1>
        <h1 className="paname">Jonathan Higgins</h1>
        <div className="pa1low">
          <div className="paage">
            <p className='lh'>Age</p>
            <p>19</p>
            </div>
            <div className="pabr"></div>
          <div className="pasex">
            <p className='lh'>Sex</p>
            <p>Female</p>
            </div>
            <div className="pabr"></div>
          <div className="paanum">
            <p className='lh'>Phone Number</p>
          <div className="panum">
            <p>+91</p>
            <p>8732020232</p>
          </div>
          </div>
        </div>
      </div>
      <div className="pbr"></div>
      <div className="pa2">
        <h1>Dr. Sasi Sharma</h1>
        <div className="patc">
          <img src={exclaim} />
          <p>Travel Consultation</p>
        </div>
      </div>
      <div className="pbr"></div>
      <div className="pa3">
        <div className="paacc">
          <img src={tick} />
          <p>Accept</p>
        </div>
        <div className="padec">
          <img src={cross} />
          <p>Decline</p>
        </div>
      </div>
    </div>
  )
}

export default Pendinfo
