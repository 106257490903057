import React from 'react'
import "./ClientDist.css"
import Chart from "./Chart"
import NewClient from '../NewClient/NewClient'

const ClientDist = () => {
  return (
    <div className="cd">
      <h1 className="cd1">Client Distribution</h1>
      <Chart/>
      <h1 className="newc">New Clients</h1>
      <NewClient/>
    </div>
  )
}

export default ClientDist
