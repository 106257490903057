import React from "react";
import "./Admin.css";
import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";
import Pending from "../../../components/Admin/Pending/Pending";
import ClientDist from "../../../components/Admin/ClientDist/ClientDist";

const Dashboard = () => {
  return (
    <>
      <AdminLayout title={"Admin Dashboard"}>
        <div className="admindash">
          <Pending />
          <ClientDist />
        </div>
      </AdminLayout>
    </>
  );
};

export default Dashboard;
