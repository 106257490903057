import React, { useState, useRef, useEffect } from "react";
import "./ClientClick.css";
import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";
import userData from "../../../assets/data/user-data.js";
import email from "../../../images/dashboard/Icons/email.png";
import cake from "../../../images/dashboard/Icons/cake.png";
import blood from "../../../images/dashboard/Icons/blood.png";
import aeroplane from "../../../images/dashboard/Icons/aeroplane.png";
import phone from "../../../images/dashboard/Icons/phone.png";
import cross from "../../../images/blackcross.png";
import SR from "../SR/SR.jsx";

const ClientClick = () => {
  const srsContainerRef = useRef(null);
  const [expandedSR, setExpandedSR] = useState(null); // Track the expanded SR

  useEffect(() => {
    if (srsContainerRef.current) {
      // Auto-scroll to the bottom when SR components are added
      srsContainerRef.current.scrollTop = srsContainerRef.current.scrollHeight;
    }
  }, []); // Add dependencies if needed to track when new SRs are added

  const toggleSR = (index) => {
    if (expandedSR === index) {
      setExpandedSR(null); // Collapse if the same SR is clicked
    } else {
      setExpandedSR(index); // Expand the clicked SR
    }
  };

  return (
    <AdminLayout title={"Client List"}>
      <div className="clibox">
        <div className="clih">
          <h1>User Information</h1>
          <img src={cross} alt="Close" />
        </div>
        <div className="clid">
          <div className="cli-card">
            <div className="cli-header">
              <img className="cli-avatar" src={userData.image} alt="User" />
              <div>
                <h2 className="cli-name">{userData.name}</h2>
                <p className="cli-tier">{userData.tier}</p>
              </div>
            </div>
            <div className="cli-details">
              <p>
                <img src={email} alt="Email" />
                {userData.email}
              </p>
              <p>
                <img src={cake} alt="Birthday" />
                {userData.birthday}
              </p>
              <p>
                <img src={blood} alt="Blood Type" />
                {userData.bloodType}
              </p>
              <p>
                <img src={aeroplane} alt="Travel" />
                {userData.travel}
              </p>
              <p>
                <img src={phone} alt="Phone" />
                {userData.phone}
              </p>
            </div>
          </div>
          <div className="clidright">
            <p>Session Reports</p>
            <div className="srs" ref={srsContainerRef}>
              {[...Array(6)].map((_, index) => (
                <SR
                  key={index}
                  index={index}
                  isExpanded={expandedSR === index}
                  onToggle={() => toggleSR(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ClientClick;
