import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./MentalHealthSidebar.css";
import logo from "../../images/dashboard/Icons/LOGO.png";

const MentalHealthSidebar = ({ step, completedSteps }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const stepPermissions = [
    {
      stepStart: 0,
      stepEnd: 1,
      path: "/mental-health-assessment/step/0",
      title: "Moods and Emotions",
      description: "Understanding your emotions can enhance our ability to support you effectively.",
    },
    {
      stepStart: 2,
      stepEnd: 3,
      path: "/mental-health-assessment/step/2",
      title: "Anxiety and Panic",
      description: "Let us know what makes you anxious or panic to help us reduce those emotions.",
    },
    {
      stepStart: 4,
      stepEnd: 5,
      path: "/mental-health-assessment/step/4",
      title: "Stress and Irritability",
      description: "How have you been doing lately? Hope everything is alright.",
    },
    {
      stepStart: 6,
      stepEnd: 7,
      path: "/mental-health-assessment/step/6",
      title: "Loss of Motivation and Energy",
      description: "We hope we can help you feel less demotivated after this assessment",
    },
    {
      stepStart: 8,
      stepEnd: 16,
      path: "/mental-health-assessment/step/8",
      title: "Physical Manifestations",
      description: "Your mental health is just as important as your physical health.",
    },
  ];

  return (
    <>
      {/* Hamburger icon */}
      <div className={`mha-hamburger ${isSidebarOpen ? "open" : ""}`} onClick={toggleSidebar}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Sidebar */}
      <div className={`mha-sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="mha-sidebar-top">
          <div className="mha-logo">
            <img src={logo} alt="Logo" />
            <h1>JetSetMed</h1>
          </div>
        </div>
        <div className="mha-title">
          <h1>How's your mental wellbeing today?</h1>
          <p>
            Don't overlook your mental health, fill this questionnaire to assess your wellbeing.
          </p>
        </div>
        <div className="mha-steps">
          {stepPermissions.map((stepInfo, index) => (
            <NavLink
              key={index}
              to={stepInfo.path}
              className={`mha-step ${
                step >= stepInfo.stepStart && step <= stepInfo.stepEnd
                  ? "active"
                  : ""
              }`}
              onClick={(e) => {
                if (completedSteps.includes(stepInfo.stepStart)) {
                  setErrorMessage("");
                } else {
                  setErrorMessage(
                    `Please complete the previous steps before accessing "${stepInfo.title}".`
                  );
                  e.preventDefault();
                }
              }}
            >
              <div className="mha-circle">
                {completedSteps.includes(stepInfo.stepEnd) ? "✓" : index + 1}
              </div>
              <div className="mha-step-content">
                <p className="mha-step-title">{stepInfo.title}</p>
                <p className="mha-step-description">{stepInfo.description}</p>
              </div>
            </NavLink>
          ))}
        </div>
        {errorMessage && (
          <div className="mha-sidebar-error-message">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MentalHealthSidebar;
