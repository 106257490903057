import React from "react";
import videoUrl from "../../images/video2.mp4";
const VideoSection = () => {
  return (
    <section id="videoSection">
      <video src={videoUrl} style={{ width: "100%", height: "100%" }} autoPlay muted playsInline loop></video>
    </section>
  );
};

export default VideoSection;
