import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/dashboard/Icons/logogreen.png";
import { jsPDF } from "jspdf";
import "../TRADescription/TRADescription.css";

const TRASuccess = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    console.log(localStorage.getItem('traProgressData'))
    // Load saved answers from localStorage
    const savedProgress = JSON.parse(localStorage.getItem('traProgressData') || '[]');
    if (savedProgress.length > 0) {
      setAnswers(savedProgress);
    }
  }, []);

  const handleDownloadPDF = () => {
    setIsLoading(true);
    const doc = new jsPDF();
    
    doc.setFontSize(20);
    doc.text("Travel Risk Assessment", 105, 15, null, null, "center");
    
    doc.setFontSize(12);
    let yPosition = 30;
    
    answers.forEach((item, index) => {
      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }
      doc.text(`Q${index + 1}: ${item.question}`, 10, yPosition);
      yPosition += 10;
      doc.text(`A: ${item.answer}`, 20, yPosition);
      yPosition += 15;
    });
    
    doc.save("travel_risk_assessment.pdf");
    setIsLoading(false);
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="bg2">
      <div className="t2logo">
        <img src={logo} alt="JetSetMed Logo" />
        <h1>JetSetMed</h1>
      </div>
      <div className="secttra2">
        <h2>Travel Risk Assessment Completed!</h2>
        <p>
          Congratulations! You've successfully completed your Travel Risk
          Assessment. Your responses have been recorded and will be reviewed by
          our medical professionals.
        </p>
        <p>
          You can now download a PDF copy of your assessment for your records.
        </p>
      </div>

      <div className="next-btn-container">
        <button
          onClick={handleDownloadPDF}
          className="auth-btn"
          disabled={isLoading}
        >
          {isLoading ? "Generating PDF..." : "Download PDF"}
        </button>
        <button onClick={handleBackToDashboard} className="auth-btn">
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default TRASuccess;
