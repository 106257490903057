import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/jetsetmedwhitelogo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import { colors } from "../../assets/color/color";
import { AiOutlineHome } from "react-icons/ai";
import { CiCircleInfo } from "react-icons/ci";
import { IoMedkitOutline } from "react-icons/io5";
import { IoPricetagsOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import whatsapp from "../../images/whatsapp.png";

const Navbar = () => {
  const [toggle, setToggle] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleToggleBtn = () => {
    console.log(toggle);
    setToggle(!toggle);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    window.location.reload();
  };

  return (
    <section id="navbar">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logoJetSetMed">
            <img src={logo} alt="logoMain" />
          </div>
          <div className="nav_menu">
            <span>HOME</span>
            <span>ABOUT US</span>
            <span>MEDICAL KIT</span>
            <span>PRICING</span>
            {isLoggedIn ? (
              <span onClick={handleLogout}>LOGOUT</span>
            ) : (
              <>
                <Link to="/login"><span>LOGIN</span></Link>
                <Link to="/signup"><span>SIGN UP</span></Link>
              </>
            )}
          </div>
          <div className="menuBar" onClick={handleToggleBtn}>
            <span>
              {toggle ? (
                <HiOutlineBars3
                  color="white"
                  fontSize={35}
                />
              ) : (
                <RxCross1 color="white" fontSize={35} />
              )}
            </span>
          </div>
          {!toggle && (
            <div className="navModelOpen" s>
              <div className="upper">
                <div className="menuItem">
                  <span>
                    <AiOutlineHome />
                  </span>
                  <span>Home</span>
                </div>

                <div className="menuItem">
                  <span>
                    <CiCircleInfo />
                  </span>
                  <span>About</span>
                </div>

                <div className="menuItem">
                  <span>
                    <IoMedkitOutline />
                  </span>
                  <span>MEDICAL KIT</span>
                </div>
                <div className="menuItem">
                  <span>
                    <IoPricetagsOutline />
                  </span>
                  <span>PRICING</span>
                </div>
                {isLoggedIn ? (
                  <div className="menuItem" onClick={handleLogout}>
                    <span>LOGOUT</span>
                  </div>
                ) : (
                  <>
                    <Link to="/login">
                      <div className="menuItem">
                        <span>LOGIN</span>
                      </div>
                    </Link>
                    <Link to="/signup">
                      <div className="menuItem">
                        <span>SIGN UP</span>
                      </div>
                    </Link>
                  </>
                )}
              </div>
              <div className="lower"></div>
            </div>
          )}
        </div>
      </div>
      <img src={whatsapp} className="wa-icon" />
    </section>
  );
};

export default Navbar;
