import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const ResetPasswordComp = ({ token }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "password") {
      setPassword(value);
    } else if (id === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/resetPassword`,
        { token, newPassword: password }
      );

      if (response.data.success) {
        toast.success("Password reset successful!");
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        navigate('/login');
      }
    } catch (error) {
      console.error("Password reset failed:", error.response?.data?.message || error.message);
      toast.error("Password reset failed. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="loginForm d-flex justify-content-center align-items-center h-100 w-100" style={{ flexDirection: "column" }}>
      {error && <div className="alert alert-danger w-100 mb-2">{error}</div>}
      <div className="w-100 mb-2">
        <label htmlFor="password">New Password</label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={handleChange}
          required
        />
      </div>
      <div className="w-100 mb-2">
        <label htmlFor="confirmPassword">Confirm New Password</label>
        <input
          type="password"
          className="form-control"
          id="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          required
        />
      </div>
      <div className="w-100 my-3">
        <button type="submit" className="btn authBtn">
          <span>Reset Password</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordComp;
