import React from 'react'
import "./Pending.css"
import Pendinfo from '../pendinfo/Pendinfo'

const Pending = () => {
  return (
    <div className="pend">
      <h1>Pending Appointments</h1>
      <Pendinfo/>
      <Pendinfo/>
      <Pendinfo/>
      <Pendinfo/>
      <Pendinfo/>
      <Pendinfo/>
    </div>
  )
}

export default Pending
