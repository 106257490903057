import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./QNASidebar.css";
import logo from "../../images/dashboard/Icons/LOGO.png";

const QNASidebar = ({ step, completedSteps }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const stepPermissions = [
    {
      stepStart: 0,
      stepEnd: 1,
      path: "/travel-risk-assessment/step/0",
      title: "Personal Information",
      description: "If we get to know you better, we can help you better.",
    },
    {
      stepStart: 2,
      stepEnd: 3,
      path: "/travel-risk-assessment/step/2",
      title: "Travel Information",
      description:
        "Knowing where you are going helps us reach you wherever you are.",
    },
    {
      stepStart: 4,
      stepEnd: 5,
      path: "/travel-risk-assessment/step/4",
      title: "General Health Status",
      description:
        "How have you been doing lately? Hope everything is alright.",
    },
    {
      stepStart: 6,
      stepEnd: 7,
      path: "/travel-risk-assessment/step/6",
      title: "Personal History",
      description: "This helps us better understand your needs.",
    },
    {
      stepStart: 8,
      stepEnd: 32,
      path: "/travel-risk-assessment/step/8",
      title: "Mental Health",
      description:
        "Your mental health is just as important as your physical health.",
    },
  ];

  return (
    <>
      {/* Hamburger icon */}
      <div className={`hamburger ${isSidebarOpen ? "open" : ""}`} onClick={toggleSidebar}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Sidebar */}
      <div className={`qnasidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="qnasidebar-top">
          <div className="qnas-logo">
            <img src={logo} alt="Logo" />
            <h1>JetSetMed</h1>
          </div>
        </div>
        <div className="qnatitle">
          <h1>Let us take care of your health</h1>
          <p>
            Fill in this questionnaire to help us help you to the best of our
            ability!
          </p>
        </div>
        <div className="qna-steps">
          {stepPermissions.map((stepInfo, index) => (
            <NavLink
              key={index}
              to={stepInfo.path}
              className={`qna-step ${
                step >= stepInfo.stepStart && step <= stepInfo.stepEnd
                  ? "active"
                  : ""
              }`}
              onClick={(e) => {
                if (completedSteps.includes(stepInfo.stepStart)) {
                  setErrorMessage("");
                } else {
                  setErrorMessage(
                    `Please complete the previous steps before accessing "${stepInfo.title}".`
                  );
                  e.preventDefault();
                }
              }}
            >
              <div className="circle">
                {completedSteps.includes(stepInfo.stepEnd) ? "✓" : index + 1}
              </div>
              <div className="step-content">
                <p className="step-title">{stepInfo.title}</p>
                <p className="step-description">{stepInfo.description}</p>
              </div>
            </NavLink>
          ))}
        </div>
        {errorMessage && (
          <div className="sidebar-error-message">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default QNASidebar;
