import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./MentalHealthAssessment.css";
import MentalQues from "../../../components/mental/MentalQues/MentalQues";
import MHALayout from "../../../Layouts/MHLayout/MHLayout";
import mentalData from "../../../../src/assets/data/mental-data";

const MentalHealthAssessment = () => {
  const { stepId } = useParams();
  const [step, setStep] = useState(Number(stepId) || 0);
  const [answers, setAnswers] = useState({});
  const [completedSteps, setCompletedSteps] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (stepId && Number(stepId) !== step) {
      setStep(Number(stepId));
    }
  }, [stepId, step]);

  const markStepComplete = () => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps((prev) => [...prev, step]);
    }
  };

  useEffect(() => {
    const allAnswered = mentalData[step]?.questions.every((q) => answers[q] !== undefined);
    if (allAnswered) {
      markStepComplete();
    }
  }, [answers, step]);

  const nextStep = () => {
    if (step < mentalData.length - 1) {
      setStep(step + 1);
      navigate(`/mental-health-assessment/step/${step + 1}`);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
      navigate(`/mental-health-assessment/step/${step - 1}`);
    }
  };

  return (
    <MHALayout step={step} completedSteps={completedSteps}>
      <div className="mha-App">
        <MentalQues
          step={step}
          questions={mentalData[step].questions}
          nextStep={nextStep}
          prevStep={prevStep}
          noOfPages={mentalData.length}
          answers={answers}
          setAnswers={setAnswers}
          markStepComplete={markStepComplete}
        />
      </div>
    </MHALayout>
  );
};

export default MentalHealthAssessment;
