import React from 'react'
import "./NewClient.css"
import prof from "../../../images/clientprof.png"
import premium from "../../../images/premiumclient.png"

const NewClient = () => {
  return (<>
    <div className="ncl">
        <div className="nclleft">
      <img src={prof} />
      <div className="nctxt">
        <h1>Green M&M</h1>
        <p>Bengaluru, India</p>
        </div>
      </div>
        <img src={premium} />
    </div>
    <div className="ncl">
        <div className="nclleft">
      <img src={prof} />
      <div className="nctxt">
        <h1>Sledge Hammer</h1>
        <p>Bengaluru, India</p>
        </div>
      </div>
    </div>
  </>  
  )
}

export default NewClient
