import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/dashboard/Icons/LOGO.png";
import mha from "../../../images/dashboard/Icons/mentalOpening.png";
import "./MHAOpening.css";

const MHAOpening = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/mental-health-assessment/description");
  };

  return (
    <div className="mha-bg">
      <div className="mha-opening-logo">
        <img src={logo} alt="JetSetMed Logo" />
        <h1>JetSetMed</h1>
      </div>
      <div className="mha-sect">
        <div className="mha-image">
          <img src={mha} alt="Mental Health Opening" />
        </div>
        <div className="mha-txtsec">
          <h1>Welcome to our</h1>
          <h1>Mental Health Screening</h1>
        </div>
      </div>
      <div className="mha-next-btn-container">
        <button onClick={handleNext} className="mha-next-btn">
          Next {"->"}
        </button>
      </div>
    </div>
  );
};

export default MHAOpening;
