import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import {  useNavigate} from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';

const LoginForm = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/login`,
        formData
      );

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role);
        toast.success("Login successful!");
        onLoginSuccess();
      }
    } catch (error) {
      console.error("Login failed:", error.response?.data?.message || error.message);
      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="loginForm">
      <div className="d-flex justify-content-between align-items-center  m-0 p-0 g-0 w-100"></div>
      <div className="w-100 mb-2">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="vishal@gmail.com"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="w-100 mb-2">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      <div className="d-flex justify-content-end forgetLink">
        <span onClick={() => navigate('/forget')}>Forget Your Password</span>
      </div>
      <div className="w-100 my-3">
        <button type="submit" className="btn authBtn">
          <span>Sign In</span>
          <span className="mx-2">
            <FaArrowRight />
          </span>
        </button>
      </div>
      <div className="w-100 text-center redirectLink">
        <span onClick={() => navigate('/signup')}>
          <span>Don't have an account ?</span>
          <span className="redirectLinkTag"> sign Up</span>
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
