import React from 'react'
import search from "../../images/dashboard/Icons/search.png";
import "./ClientSearch.css"

const ClientSearch = () => {
  return (
    <div className="search">
      <img src={search} alt="search icon" />
      <input type="text" placeholder="Search name or contact number" />
    </div>
  )
}

export default ClientSearch
