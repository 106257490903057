import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UpHead from "../../components/auth/UpHead";
import AuthImage from "../../components/auth/AuthImage";
import { FcGoogle } from "react-icons/fc";
import LoginForm from "../../components/auth/LoginForm";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || "/";

  const handleLoginSuccess = () => {
    navigate(from);
  };

  return (
    <div>
      <UpHead />
      <div className="loginDivider">
        <div className="left">
          <AuthImage />
        </div>
        <div className="right mx-2">
          <div className="headerPath">
            <h1>Welcome Back!</h1>
            <p>Sign up With</p>

            <span>
              <FcGoogle fontSize={30} />
            </span>
          </div>
          <div className="loginBorder">
            <div className="left"></div>
            <span className="mx-2">Or</span>
            <div className="right"></div>
          </div>
          <div className="LoginFormComp">
            <LoginForm onLoginSuccess={handleLoginSuccess} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
