import React, { useState, useEffect } from "react";
import "./Questionnaire.css";

const Questionnaire = ({
  step,
  questions,
  nextStep,
  prevStep,
  noOfPages,
  answers,
  setAnswers,
  markStepComplete,
  submitAnswers,
}) => {
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const allAnswered = questions.every((q) => answers[q.question]);
    setIsStepComplete(allAnswered);
    if (allAnswered) {
      markStepComplete();
    }
  }, [answers, questions, markStepComplete]);

  const handleChange = (e, question, option) => {
    e.preventDefault();
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: option,
    }));
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isStepComplete) {
      await submitAnswers();
    } else {
      setErrorMessage("Please complete all questions before submitting.");
    }
  };
  
  const handleNextClick = () => {
    if (isStepComplete) {
      // Validate phone number (10 digits)
      if (
        questions.some(
          (q) => q.type === "phone" && !/^\d{10}$/.test(answers[q.question])
        )
      ) {
        setErrorMessage("Please enter a valid 10-digit phone number.");
        return;
      }

      // Validate email format
      if (
        questions.some(
          (q) =>
            q.type === "email" &&
            !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(answers[q.question])
        )
      ) {
        setErrorMessage("Please enter a valid email address.");
        return;
      }

      // Validate name (no numbers allowed)
      if (
        questions.some(
          (q) =>
            q.type === "name" && !/^[A-Za-z\s'-]+$/.test(answers[q.question])
        )
      ) {
        setErrorMessage("Please enter a valid name");
        return;
      }

      nextStep();
    } else {
      setErrorMessage("Please complete all questions before proceeding.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="questionnaire">
      <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
        {questions.map((q, index) => (
          <div key={index} className="question">
            <label>{q.question}</label>
            {q.type === "boolean" && (
              <div className="options-container">
                <button
                  type="button"
                  className={`option-button ${
                    answers[q.question] === "yes" ? "selected" : ""
                  }`}
                  onClick={(e) => handleChange(e, q.question, "yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`option-button ${
                    answers[q.question] === "no" ? "selected" : ""
                  }`}
                  onClick={(e) => handleChange(e, q.question, "no")}
                >
                  No
                </button>
              </div>
            )}
            {(q.type === "text" ||
              q.type === "name" ||
              q.type === "phone" ||
              q.type === "email") && (
              <input
                type="text"
                name={q.question}
                value={answers[q.question] || ""}
                onChange={(e) => handleChange(e, q.question, e.target.value)}
              />
            )}
            {q.type === "multiple" && (
              <div className="options-container">
                {q.options.map((option, i) => (
                  <button
                    type="button"
                    key={i}
                    className={`option-button ${
                      answers[q.question] === option ? "selected" : ""
                    }`}
                    onClick={(e) => handleChange(e, q.question, option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
            {q.type === "slider" && (
              <div className="slider-container">
                <input
                  type="range"
                  min="1"
                  max="10"
                  name={q.question}
                  value={answers[q.question] || "1"}
                  onChange={(e) => handleChange(e, q.question, e.target.value)}
                  className="custom-slider"
                />
                <div className="slider-thumb-value">
                  {answers[q.question] || "1"}
                </div>
              </div>
            )}
          </div>
        ))}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="navigation">
          {step > 0 && (
            <button type="button" className="back-btn" onClick={prevStep}>
              {"<-"}Back
            </button>
          )}
          {step < noOfPages - 1 && (
            <button
              type="button"
              className="next-btn"
              onClick={handleNextClick}
            >
              Next{"->"}
            </button>
          )}
          {step === noOfPages - 1 && (
            <button type="submit" className="next-btn">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Questionnaire;
