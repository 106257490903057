import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./TravelRiskAssessment.css";
import Questionnaire from "../../../components/qna/Questionnaire/Questionnaire";
import QNALayout from "../../../Layouts/QNALayout/QNALayout";
import qnaData from "../../../../src/assets/data/qna-data";
import axios from "axios";
import { toast } from "react-toastify";

const TravelRiskAssessment = () => {
  const { stepId } = useParams();
  const [step, setStep] = useState(Number(stepId) || 0);
  const [answers, setAnswers] = useState({});
  const [completedSteps, setCompletedSteps] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (stepId && Number(stepId) !== step) {
      setStep(Number(stepId));
    }
  }, [stepId, step]);

  useEffect(() => {
    // Load saved progress from localStorage
    const savedProgress = JSON.parse(localStorage.getItem('traProgressData') || '[]');
    if (savedProgress.length > 0) {
      const savedAnswers = savedProgress.reduce((acc, item) => {
        acc[item.question] = item.answer;
        return acc;
      }, {});
      setAnswers(savedAnswers);
      const maxStep = Math.max(...savedProgress.map(item => item.step));
      setCompletedSteps(Array.from({ length: maxStep + 1 }, (_, i) => i));
    }
  }, []);

  const saveProgress = () => {
    const currentStepQuestions = qnaData[step].questions;
    const savedProgress = JSON.parse(localStorage.getItem('traProgressData') || '[]');

    // Remove existing entries for the current step
    const filteredProgress = savedProgress.filter(item => item.step !== step);

    // Add new entries for the current step
    const newEntries = currentStepQuestions.map(q => ({
      step: step,
      question: q.question,
      answer: answers[q.question] || ''
    })).filter(item => item.answer !== '');

    const updatedProgress = [...filteredProgress, ...newEntries];
    localStorage.setItem('traProgressData', JSON.stringify(updatedProgress));
  };

  const markStepComplete = () => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps((prev) => [...prev, step]);
    }
  };

  const nextStep = () => {
    if (step < qnaData.length - 1) {
      saveProgress();
      markStepComplete();
      setStep(step + 1);
      navigate(`/travel-risk-assessment/step/${step + 1}`);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
      navigate(`/travel-risk-assessment/step/${step - 1}`);
    }
  };

  const submitAnswers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/qnas`,
        {
          qna: Object.entries(answers).map(([question, answer]) => ({ question, answer })),
          pdfPath: ""
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.success) {
        toast.success("Answers submitted successfully!");
        navigate("/travel-risk-assessment/success");
      } else {
        toast.error("Failed to submit answers. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <QNALayout step={step} completedSteps={completedSteps}>
      <div className="App">
        <Questionnaire
          step={step}
          questions={qnaData[step].questions}
          nextStep={nextStep}
          prevStep={prevStep}
          noOfPages={qnaData.length}
          answers={answers}
          setAnswers={setAnswers}
          markStepComplete={markStepComplete}
          submitAnswers={submitAnswers}
        />
      </div>
    </QNALayout>
  );
};

export default TravelRiskAssessment;
