import React, { useState, useEffect } from "react";
import "./MentalQues.css";

const MentalQues = ({
  step,
  questions,
  nextStep,
  prevStep,
  noOfPages,
  answers,
  setAnswers,
  markStepComplete,
}) => {
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const allAnswered = questions.every((q) => answers[q] !== undefined);
    setIsStepComplete(allAnswered);
    if (allAnswered) {
      markStepComplete();
    }
  }, [answers, questions, markStepComplete]);

  const handleChange = (e, question, option) => {
    e.preventDefault();
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: option,
    }));
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isStepComplete) {
      alert("Form Submitted");
    } else {
      setErrorMessage("Please complete all questions before submitting.");
    }
  };

  const handleNextClick = () => {
    if (isStepComplete) {
      nextStep();
    } else {
      setErrorMessage("Please complete all questions before proceeding.");
    }
  };

  return (
    <div className="mha-questionnaire">
      <form onSubmit={handleSubmit}>
        {questions.map((q, index) => (
          <div key={index} className="mha-question">
            <label>{q}</label>
            <div className="mha-options-container">
              {[0, 1, 2, 3].map((option, i) => (
                <button
                  type="button"
                  key={i}
                  className={`mha-option-button ${
                    answers[q] === option ? "mha-selected" : ""
                  }`}
                  onClick={(e) => handleChange(e, q, option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        ))}
        {errorMessage && <p className="mha-error-message">{errorMessage}</p>}
        <div className="mha-navigation">
          {step > 0 && (
            <button type="button" className="mha-back-btn" onClick={prevStep}>
              {"<-"}Back
            </button>
          )}
          {step < noOfPages - 1 && (
            <button
              type="button"
              className="mha-next-btn"
              onClick={handleNextClick}
            >
              Next{"->"}
            </button>
          )}
          {step === noOfPages - 1 && (
            <button type="submit" className="mha-next-btn">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default MentalQues;
