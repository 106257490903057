import React from "react";
import "./SR.css";
import neww from "../../../images/srnew.png";
import down from "../../../images/srdown.png";
import seen from "../../../images/srseen.png";

const SR = ({ index, isExpanded, onToggle }) => {
  return (
    <div className="srbox" onClick={onToggle}>
      <div className="srt">
        <p>Session {index + 1} - 8th April, 2024</p>
        <img src={neww} className="srtimg"/>
      </div>
      <div className="srb">
        <div className="srbl">
          <h1>Ophthalmologist</h1>
          <p>Dr. Sasi Sharma</p>
        </div>
        <img src={down} alt="Expand/Collapse" />
      </div>
      {isExpanded && (
        <div className="sr-notes">
          <textarea
            placeholder="Session notes..."
            className="sr-textarea"
          ></textarea>
        </div>
      )}
    </div>
  );
};

export default SR;
