import React from "react";
import "./MHLayout.css";
import MentalHealthSidebar from "../../Utilities/MentalHealthSidebar/MentalHealthSidebar";

const MHLayout = ({ children, step, completedSteps }) => {
  return (
    <div className="mha-dashboard-container">
      <div className="mha-layout-left">
        <MentalHealthSidebar step={step} completedSteps={completedSteps} />
      </div>
      <div className="mha-layout-right">{children}</div>
    </div>
  );
};

export default MHLayout;
